/** Date 객체를 yyyy-mm-dd 형식의 string으로 반환 */
export const dateFormatter = (date: Date) => {
  const result = date
    ?.toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\./g, '')
    .replace(/\s/g, '-');

  return result;
};

/** 입력 시 자동으로 yyyy-mm-dd 형식에 맞춰줌 */
export const dateAutoFormatter = (before: string, after: string) => {
  //   추가된 글자가 숫자가 아니거나 10글자 초과할 시 삭제해서 반환
  //  새로 입력되는 글씨가 이전보다 더 많으면서 4번째 혹은 7번째 글자면 뒤에 - 추가
  if (!isInteger(after.slice(-1)) || after.length > 10) return before;
  // 여기는 달은 1~12 일은 1~31 맞춰주는 건데.. 중간에 포커스 처리는 못해줘서 일단 막아놓음
  // 대신 onBlur에서 validate 체크 해주는 걸로 대체함~!

  // if (after.length >= 6 && after[5] !== '0' && after[5] !== '1') return before;
  // if (
  //   after.length >= 7 &&
  //   (parseInt(after.slice(5, 7)) < 1 || parseInt(after.slice(5, 7)) > 12)
  // )
  //   return before;
  // if (after.length >= 9 && (after[8] < '0' || after[8] > '3')) return before;
  // if (
  //   after.length >= 10 &&
  //   (parseInt(after.slice(8, 10)) < 1 || parseInt(after.slice(8, 10)) > 31)
  // )
  //   return before;
  if (before < after && (after.length === 4 || after.length === 7))
    return after + '-';
  return after;
};

/** yyyy-mm-dd가 유효한 날짜인지 t/f 반환 */
export const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return date instanceof Date && dateFormatter(date) === dateString;
};

/** yyyy-mm-dd가 1900-01-01 ~ 오늘 사이인지 확인해 t/f 반환 */
export const isInDateRange = (dateString: string, today: Date) => {
  const firstDate = new Date(Date.UTC(0, 0, 0, 0, 0, 0));

  const dateParts = dateString.split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // JavaScript의 월은 0부터 시작합니다
  const day = parseInt(dateParts[2]);

  const comparedDate = new Date(year, month, day);

  return firstDate <= comparedDate && comparedDate <= today;
};

/** 해당 string이 정수인지 확인.. 이런 것도 없다니 역시 자바 스크립트 */
const isInteger = (str: string) => {
  for (let i = 0; i < str.length; i++) {
    if ((str[i] < '0' || str[i] > '9') && str[i] !== '-') return false;
  }
  return true;
};
/** yyyy-mm-dd를 yyyy.mm.dd로 변환해주는 함수 */
export const DateBarToDot = (str: string) => {
  return str.replace(/-/g, '.');
};

/** 두 개 날짜 사이의 나이차를 소수점 형식으로 반환하는 함수 */
export const convertYearAge = (exam_date: string, birth_date: string) => {
  const examDate = new Date(exam_date);
  const birthDate = new Date(birth_date);

  const oneDay = 24 * 60 * 60 * 1000;
  const delta = (examDate.getTime() - birthDate.getTime()) / oneDay;

  const yearAge = delta / 365.25;

  return yearAge;
};

/* 나이 변환기 */
export const convertDate = (
  findYearAge: number,
  baseDate: string,
  baseYearAge: number,
) => {
  const days = (age: number) => {
    return age * 365.25;
  };
  const birthDate = new Date(baseDate);
  birthDate.setDate(birthDate.getDate() - days(baseYearAge) + 1);

  // 굳이 아래처럼 계산한 이유 : 정수는 latest growth curve에서만 발생
  // 실제 age값은 2010-01-01 생일자가 2015-01-01에 exam 등록해도 정수로 떨어지지 않고 소수점 단위의 미묘한 차이가 발생함
  // 오히려 latest growth curve이 특수한 케이스로서 이를 처리할 의도로 작성됨
  const findDate = new Date(birthDate);
  if (Number.isInteger(findYearAge)) {
    findDate.setFullYear(birthDate.getFullYear() + findYearAge);
  } else findDate.setDate(birthDate.getDate() + days(findYearAge) - 1);

  return dateFormatter(findDate);
};

// 실수 나이를 Y, M 객체형식으로 반환하는 함수
export const convertYM = (yearAge: number) => {
  const y = Math.floor(yearAge);
  const m = Math.floor((yearAge - y) * 12);

  if (yearAge < 0) return { Y: 0, M: 0 };

  return { Y: y, M: m };
};
