/** 오브젝트의 empty 여부만 반환합니다. 일반적으로 생각하는 isEmpty랑 다름 주의! */
const isObjectEmpty = <T>(value: T | null | undefined): boolean => {
  if (typeof value !== 'object' || value === null) return false;
  return Object.keys(value).length === 0;
};

interface UnknownObject {
  [key: string]: unknown;
}

/** 객체 값이 전부 undefined면 undefined 반환합니다 */
export const undefinedObjectToUndefined = (obj: UnknownObject) => {
  for (const key in obj) {
    if (isObjectEmpty(obj[key])) obj[key] = undefined;
    else if (typeof obj[key] === 'object') {
      const arr = Object.values(obj[key] as UnknownObject);
      if (arr.filter(value => value === undefined).length === arr.length)
        obj[key] = undefined;
      else obj[key] = undefinedObjectToUndefined(obj[key] as UnknownObject);
    }
    // 위에서 변경 적용으로 인해 다시 한 번 obj[key]의 자식이 전부 undefined가 되었을 경우 한 번 더 처리
    if (typeof obj[key] === 'object') {
      const arr2 = Object.values(obj[key] as UnknownObject);
      if (arr2.filter(v => v === undefined).length === arr2.length)
        obj[key] = undefined;
    }
  }

  return obj;
};

/** object에서 Nan값과 빈 문자열, 빈 객체를 undefined로 바꿔주는 재귀 함수 */
export const NanToUndefined = (object: UnknownObject): UnknownObject => {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      if (typeof object[key] === 'number' && Number.isNaN(object[key]))
        object[key] = undefined;
      else if (object[key] === '') object[key] = undefined;
      else if (typeof object[key] === 'object' && isObjectEmpty(object[key]))
        object[key] = undefined;
      else if (typeof object[key] === 'object' && !isObjectEmpty(object[key]))
        object[key] = NanToUndefined(object[key] as UnknownObject);
    }
  }
  return object;
};

/** object에서 undefined과 NaN을 제거한 새 object를 반환합니다 */
export const removeUndefined = <T>(obj: T): T => {
  const newObj: Partial<T> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] !== undefined && !Number.isNaN(obj[key])) {
        newObj[key] = obj[key];
      }
    }
  }
  return newObj as T;
};

/** string | number | undefined -> number */
export const toNumber = (str: string | number | undefined) => {
  if (typeof str === 'string') return parseFloat(str);
  else if (typeof str === 'number') return str;
  else return 0;
};

/** undefined일 수 있는 number들을 덧셈. 인자가 undefined일 경우 undefined 반환 */
export const safePlus = (a: number | undefined, b: number | undefined) => {
  if (typeof a === 'number' && typeof b === 'number') return a + b;
  else return undefined;
};
/** undefined일 수 있는 number들을 뺄셈. 인자가 undefined일 경우 undefined 반환 */
export const safeMinus = (a: number | undefined, b: number | undefined) => {
  if (typeof a === 'number' && typeof b === 'number') return a - b;
  else return undefined;
};
/** undefined일 수 있는 number들을 곱셈. 인자가 undefined일 경우 undefined 반환 */
export const safeMultiply = (a: number | undefined, b: number | undefined) => {
  if (typeof a === 'number' && typeof b === 'number') return a * b;
  else return undefined;
};
/** undefined일 수 있는 number들을 나눗셈. 인자가 undefined일 경우 undefined 반환 */
export const safeDivide = (a: number | undefined, b: number | undefined) => {
  if (typeof a === 'number' && typeof b === 'number') return a / b;
  else return undefined;
};

export const isPassword = (password: string) => {
  const lengthRegex = /^.{8,16}$/;
  const englishRegex = /[A-Za-z]/;
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

  if (!lengthRegex.test(password)) {
    return false;
  }
  if (!englishRegex.test(password)) {
    return false;
  }
  if (!numberRegex.test(password)) {
    return false;
  }
  if (!specialCharRegex.test(password)) {
    return false;
  }
  return true;
};

/** flat한 객체 배열에서 중복을 제거합니다 */
export const removeDuplication = <T extends object>(arr: T[]) => {
  return arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex(t =>
        Object.keys(t).every(
          key => t[key as keyof T] === value[key as keyof T],
        ),
      ),
  );
};

/**
 * 소수를 자릿수만큼 잘라서 반환합니다. js의 부동 소수점 오차 문제 등에 사용
 * @param number 자를 소수
 * @param digit 자릿수는 절대값 사용
 * @example
 * precision(0.123456, 3);
 * // return 0.123
 * precision(0.56789, 2);
 * // return 0.56
 */
export const precisionFixed = (number: number, digit: number) => {
  const correctedDigit = digit < 0 ? 0 : Math.floor(digit);
  if (number < 0)
    return Math.ceil(number * 10 ** correctedDigit) / 10 ** correctedDigit;
  else return Math.floor(number * 10 ** correctedDigit) / 10 ** correctedDigit;
};

export const makeDropdownArray = (
  displayValues: string[],
  rawValues: string[],
) => {
  const rst: { displayValue: string; rawValue: string }[] = [];
  if (displayValues === rawValues)
    displayValues.forEach((ele, index) => {
      rst.push({ displayValue: ele, rawValue: rawValues[index] });
    });
  return rst;
};
