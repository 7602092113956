import { FieldErrors } from 'react-hook-form';

/** errors 객체에서 path에 에러가 존재하는 지 검사하는 함수 */
export const isError = (path: string, errors: FieldErrors) => {
  const keys = path.split('.');
  let value = errors;
  for (const key of keys) {
    if (value === undefined) break;
    value = value[key] as FieldErrors;
  }
  return value !== undefined;
};
